<template>
  <div class="taxe-emission-list-page page-layout">
    <PageHeader
      title="Calculateurs de taxe sur vehicule société (émissions de CO₂)"
      :has-actions="true"
      :has-back="true"
      @back="goBack">
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/taxe-vehicule-societe-emission/create')">
        Ajouter
      </md-button>

      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/taxe-vehicule-societe-emission/history')">
        Histoire
      </md-button>
    </PageHeader>
    <!-- La liste des calculators -->
    <sortable-list
      link="/admin/calculators/taxe-vehicule-societe-emission/update/"
      :items="data"
      :parameters="listParameters"
      :hasPagination="true">
    </sortable-list>
  </div>
</template>

<script>
    import calculatorsModel from "../../../../model/calculators";
    import PageHeader from "@/components/PageHeader";

    export default {
        components : { PageHeader },
        data() {
            return {
                isDebug        : false,
                data           : [],
                listParameters : [
                    {
                        name : "Emission min",
                        key  : "emission_min",
                    },
                    {
                        name : "Emission max",
                        key  : "emission_max",
                    },
                    {
                        name : "Coef",
                        key  : "coef",
                    },
                ],
            };
        },
        methods    : {
            goBack() {
                this.$router.push("/admin/calculators/");
            },
        },
        mounted() {
            calculatorsModel.getAllEmission().then(data => {
                this.data = data;
            });
        },
    };
</script>

<style lang="scss" scoped>
  .taxe-emission-list-page {
    .debug-log {
      max-height: 200px;
      overflow-y: scroll;
    }
  }
</style>
